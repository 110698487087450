window.prototype = this;

menuTrigger = () => {
    let nav = document.querySelector('#navMenu');
    let isShow = [...nav.classList].some(item => item === 'd-none')
    if (isShow) {
        nav.classList.remove('d-none');
        nav.classList.add('d-flex');
    } else {
        nav.classList.remove('d-flex');
        nav.classList.add('d-none');
    }
}

//faqs

const items = document.querySelectorAll(".accordion button");

toggleAccordion = (e) => {
    let item = e.target.nodeName == 'BUTTON' ? e.target : e.target.parentElement
    const itemToggle = item.getAttribute('aria-expanded');
    for (i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
    }
    if (itemToggle == 'false') {
        item.setAttribute('aria-expanded', 'true');
    }
}

items.forEach(item => item.addEventListener('click', toggleAccordion));

const header = document.querySelector('header');

window.addEventListener('scroll', (event) => {
    const isTop = window.scrollY < 35;
    const isMobile = window.innerWidth < 992;
    if(!isTop) {
        header.classList.add("shadow-lg");
        header.classList.add("scroll-bg");
        if(!isMobile){
            header.style.paddingTop = '10px';
            header.style.paddingBottom = '10px';
        }
    } else {
        if(!isMobile){
            header.style.paddingTop = '30px';
            header.style.paddingBottom = '30px';
        }
        header.classList.remove("shadow-lg")
        header.classList.remove("scroll-bg");
    }
})


// loader
let loader = document.querySelector('#loader');

openLoader = () => {
    loader.style.display = 'flex'
}

// page payment

// const stripe = document.querySelector('#stripe');
//
// selectPayment = (event) => {
//     $('.generic-errors').text('');
//     if(event.value === 'stripe'){
//         stripe.classList.remove('d-none');
//     }else {
//         stripe.classList.add('d-none');
//
//         console.log("esle")
//     }
// }


tiggerShow = (input_id, btn_id) => {
    let input = document.querySelector('#'+input_id);
    let btn = document.querySelector('#'+btn_id);
    let isShow = input.type !== 'password'
    if(isShow){
        btn.innerHTML = '<i class="fas fa-eye-slash"></i>'
        input.type = 'password'
    }else {
        btn.innerHTML = '<i class="fas fa-eye"></i>'
        input.type = 'text'
    }
}